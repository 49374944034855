<template>
    <div class="newsContainer">
        <div class="top" :style="{ backgroundImage: 'url(' + topImage + ')' }">
            <div class="topTitle">
                <div class="ch">公司新闻</div>
                <div class="en">NEWS</div>
            </div>
        </div>
        <div class="content">
            <a-table :columns="columns" :rowClassName="rowClassNameFn" :data-source="newsData" :showHeader="false" :pagination="pagination"
                :style="{ width: '1200px' }" class="newsTable">
            </a-table>
        </div>
    </div>
</template>
<script>
import api from '@/api/index.js'
const { VUE_APP_API } = process.env
export default {
    data() {
        return {
            topImage: '',
            columns: [
                {
                    title: 'title',
                    dataIndex: 'title',
                    customRender: (text, record) => {
                        const date = record.time.split(' ')[0]
                        const year = date.split('-')[0]
                        const month = date.split('-')[1]
                        const day = date.split('-')[2]
                        const redirect = (url) => {
                            url && window.open(url)
                        }
                        return (
                            <div class='newsItem' onClick={() => { redirect(record.link) }}>
                                <div class='newsTime'>
                                    <div class='day'>{day}</div>
                                    <div class='yearMonth'>{year}-{month}</div>
                                </div>
                                <div class='news'>
                                    <div class='newsTitle'>{record.title}</div>
                                    <div class='newsContent'>{record.content}</div>
                                </div>
                            </div>
                        )
                    }
                }
            ],
            newsData: [],
            pagination: {
                pageSize: 6,
            }
        }
    },
    methods: {
        async init() {
            // const channelId = (await api.getChannelsList()).filter(channel => channel.channelName === '公司新闻')[0].id
            const channelId = 33
            const channel = await api.getChannel({ channelId })
            this.topImage = channel.imageUrl?.replace('@', VUE_APP_API)?.replace('/api', '')
            const contents = (await api.getContentsList({ channelId })).contents.filter(item => item.state === '已审核') 
            this.newsData = contents.map((item, index) => {
                return {
                    key: index,
                    title: item.title,
                    content: item.body.replace(/<[^<>]+>/g, ""),
                    time: item.addDate,
                    link: item.source
                }
            })
        },
        rowClassNameFn(record, index) {
            return !record.link ? 'no-link' : '' // 如果没有link，增加class类，用于取消hover高亮
        }

    },
    mounted() {
        this.init()
    }

}
</script>
<style lang="less">
@import url(./common.less);
</style>